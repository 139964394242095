// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.

import Rails from "@rails/ujs"
import * as ActiveStorage from "@rails/activestorage"
import "channels"
import {Vimeo} from "vimeo";
import "@vimeo/player";
import Player from "@vimeo/player";

Rails.start()
ActiveStorage.start()

window.Vm = Vimeo
window.Pl = Player

const ExamTimeSetter = () => {
    let wrapper = document.getElementById('exam-video-preview')
    if (wrapper) {
        let updateInput = document.getElementById('input-seeker-' + wrapper.dataset.question)
        let lastDisplay = wrapper.dataset.display
        let url = wrapper.dataset.url
        let options01 = {
            url: url,
            width: 600,
            autoplay: false,
            title: false,
            muted: false
        };
        let player = new Player(wrapper.id, options01);
        player.setVolume(1);
        player.setCurrentTime(parseInt(lastDisplay))
        player.on('timeupdate', function (data) {
            updateInput.value = parseInt(data.seconds)
        })
        // setInterval(function () {
        //     player.getCurrentTime().then(function (seconds) {
        //         console.log("seconds", seconds)
        //     })
        // }, 1000)
    }
}

const Test = () => {
    let wrapper = document.getElementById('vimeo-embed-frame')
    if (wrapper) {
        let url = wrapper.dataset.url

        let options01 = {
            url: url,
            width: wrapper.clientWidth - 50,
            autoplay: false,
            background: true,
            controls: false,
            keyboard: false,
            title: false,
            muted: false
        };
        let player = new Player('vimeo-embed-frame', options01);
        window.player = player
        player.setVolume(1);

        player.on('play', function() {
            console.log('Played the first video');
        });

        player.on('bufferstart', function (opts) {
            console.log('bufferstart', opts)
        });

        player.on('bufferend', function (opts) {
            console.log('bufferend', opts)
        });

        $(window).focus(function() {
            player.getPaused().then(function (paused) {
                if (paused)
                    player.play()
            })
        });

        document.addEventListener("visibilitychange", event => {
            if (document.visibilityState === "visible") {
                player.getPaused().then(function (paused) {
                    if (paused) {
                        player.play()
                    }
                })
            } else {
                player.getPaused().then(function (paused) {
                    if (!paused) {
                        player.pause()
                    }
                })
            }
        })


        // player.on('progress', function (opts) {
        //     console.log('progress', opts)
        // });
        player.setVolume(100)

        setInterval(function () {
            player.getCurrentTime().then(function (seconds) {
                console.log("seconds", seconds)
            })
        }, 1000)

        player.play()
    }
}

const LoginSelectors = () => {
    const citySelector = $('#city_selector')
    const districtSelector = $('#district_selector')
    const schoolSelector = $('#school_selector')
    citySelector.on('changed.bs.select', function (e, clickedIndex, isSelected, previousValue) {
        const value = citySelector.selectpicker('val')
        $.get('/get-districts/' + value, function (data) {
            const html = data.result.map(d => '<option value="' + d.id + '">' + d.name + '</option>').join('')
            districtSelector.html(html)
            schoolSelector.html('<option></option>')
            districtSelector.selectpicker('refresh')
            districtSelector.selectpicker('render')
            schoolSelector.selectpicker('refresh')
            schoolSelector.selectpicker('render')
        })
    });

    districtSelector.on('changed.bs.select', function (e, clickedIndex, isSelected, previousValue) {
        const value = districtSelector.selectpicker('val')
        $.get('/get-schools/' + value, function (data) {
            const html = data.result.map(d => '<option value="' + d.id + '">' + d.name + '</option>').join('')
            schoolSelector.html(html)
            schoolSelector.selectpicker('refresh')
            schoolSelector.selectpicker('render')
        })
    });
}

const ParentSelectors = () => {
    const citySelector = $('#city_selector')
    const districtSelector = $('#district_selector')
    const schoolSelector = $('#school_selector')
    citySelector.on('changed.bs.select', function (e, clickedIndex, isSelected, previousValue) {
        const value = citySelector.selectpicker('val')
        $.get('/get-exam-districts/' + value, function (data) {
            const html = data.result.map(d => '<option value="' + d.id + '">' + d.name + '</option>').join('')
            districtSelector.html(html)
            schoolSelector.html('<option></option>')
            districtSelector.selectpicker('refresh')
            districtSelector.selectpicker('render')
            schoolSelector.selectpicker('refresh')
            schoolSelector.selectpicker('render')
        })
    });

    districtSelector.on('changed.bs.select', function (e, clickedIndex, isSelected, previousValue) {
        const value = districtSelector.selectpicker('val')
        $.get('/get-exam-schools/' + value, function (data) {
            const html = data.result.map(d => '<option value="' + d.id + '">' + d.name + '</option>').join('')
            schoolSelector.html(html)
            schoolSelector.selectpicker('refresh')
            schoolSelector.selectpicker('render')
        })
    });
}

const ExamExample = () => {
    let in_exam = document.getElementById('exam_example')
    if (in_exam) {
        try {
            $('#kt_wrapper').css('padding-top', '0')
            $('#kt_header').css('display', 'none')
            $('#kt_footer').remove()
            $('#kt_content').css('overflow', 'hidden')
            $('body').css('overflow', 'hidden')
            let toggle = KTLayoutAsideToggle.getToggle()
            toggle.toggle()
            toggle.toggleOff()
        } catch (_) {
        }
    }
}

const MessageScroller = () => {
    let $destek = $('#kt_inbox_destek')
    $destek.on('shown.bs.modal', function (e) {
        let $history = $('.msg_history')
        $history.animate({ scrollTop: $history.prop("scrollHeight") }, 100);
        MessageChecker()
        if (typeof window.passiveMessageTimer !== "undefined") {
            clearInterval(window.passiveMessageTimer)
        }
    })

    $destek.on('hidden.bs.modal', function (e) {
        if (typeof window.activeMessageTimer !== "undefined") {
            clearInterval(window.activeMessageTimer)
        }

        InitialMessageChecker();
    })

    // InitialMessageChecker();
}

const MessageChecker = () => {
    // window.activeMessageTimer = setInterval(() => {
    //     let message_id = $('.active_chat').data('message-id')
    //     $.get(`/yonetici/kisisel-mesaj/${message_id}`)
    // }, 3000)
}

const InitialMessageChecker = () => {
    // window.passiveMessageTimer = setInterval(() => {
    //     $.get(`/yonetici/kisisel-mesaj`)
    // }, 3000)
}

const FinalQuestionFiller = () => {
    let $grading = $('#user_exam_grade');
    if ($grading.length > 0) {
        $grading.change(function () {
            let value = $grading.val().toString();
            let $major_field = $('#user_exam_exam_major');
            let lesson = $major_field.val();
            let options = gon.context_descriptions[lesson][value]
            let text = '<option value="" label=" "></option>'
            options.forEach((i) => text += '<option value="' + i + '" label="' + i + '">' + i + '</option>')
            $('#user_exam_context_description').html(text)
        })
    }
}
$(document).ready(function () {
    // Test();
    FinalQuestionFiller();
    ExamExample();
    ExamTimeSetter();
    if($('#kt_login_signup_form').length > 0) {
        const dependent_to_work = $('.dependent_to_work')
        dependent_to_work.hide()
        $('input[name="user[profile_attributes][currently_working]"]').change(function() {
            if ($('input[name="user[profile_attributes][currently_working]"]:checked').val() === '1') {
                dependent_to_work.show()
            } else {
                dependent_to_work.hide()
            }
        })
        $("#user_profile_attributes_phone").inputmask("mask", {
            "mask": "[0](999) 999-9999"
        });
        LoginSelectors();
    }

    if ($('#parent-validation-form').length > 0)
        ParentSelectors();
    // MessageScroller();
})
require("trix")
require("@rails/actiontext")