import consumer from "./consumer"

const answerChannel = consumer.subscriptions.create("AnswerChannel", {
  connected() {
    // Called when the subscription is ready for use on the server
    window.answer_channel_connection = true
    console.log("Connected to answers")
  },

  disconnected() {
    // Called when the subscription has been terminated by the server
    window.answer_channel_connection = false
  },

  received(data) {
    console.log(data)
    // Called when there's incoming data on the websocket for this channel
  }
});

export default answerChannel;